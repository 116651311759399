import { HacsJobSchema } from "@/features/hacs";

export const loadBlazeRuns = (key: string): Required<HacsJobSchema>[] => {
  if (localStorage) {
    try {
      const data = localStorage.getItem(key);
      if (data) {
        return JSON.parse(data);
      }
    } catch (err) {
      console.log("localStorage Error:", err);
    }
  }

  return [];
};

export const saveBlazeRuns = (schemas: Required<HacsJobSchema>[], key: string) => {
  if (localStorage) {
    try {
      localStorage.setItem(key, JSON.stringify(schemas));
    } catch (err) {
      console.log("localStorage Error:", err);
    }
  }
};
