import { Loading } from "@/components/overlays/Loading";
import { useGetCurrentUserQuery } from "@/store/services/supabase";
import { useRouter } from "next/router";
import { useEffect } from "react";
export const PasswordNeedsChangeCheck = ({
  children
}: {
  children: JSX.Element;
}) => {
  const router = useRouter();
  const {
    data: user
  } = useGetCurrentUserQuery();
  useEffect(() => {
    if (user && user.needs_password_reset) {
      router.push("/change-password");
    }
  }, [user]);
  if (!user) {
    return <Loading />;
  }
  return children;
};