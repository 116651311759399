import { downloadPlainTextFile } from "@/features/custom_downloads";
import { HacsJobMonitor, HacsJobSchema } from "@/features/hacs";
import { RemoteFailedJobActions, RemoteJobActions, RemoteJobActionsProps } from "@/features/remote_jobs";
import { sendDataToHubspotForm } from "@/features/tracking";
import { BlazeJobFailModal, BlazeJobFailModeId } from "@/tools/aggregate/blaze/components/BlazeJobFailModal";
import { AuditOutlined, CloudDownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSession } from "@supabase/auth-helpers-react";
import { useTranslation } from "react-i18next";
const REQUEST_APPROVAL_FORM = "2382a741-b431-4a59-9b48-6275bd40c873";
interface BlazeJobActionsProps extends Omit<RemoteJobActionsProps<HacsJobSchema>, "completedActions" | "state"> {
  onApprovalRequested: () => void;
  schema: Required<HacsJobSchema>;
  failModeId?: BlazeJobFailModeId;
}
export const BlazeJobActions = ({
  onApprovalRequested,
  schema,
  failModeId = "debugInfo",
  ...props
}: BlazeJobActionsProps) => {
  const session = useSession();
  const {
    t
  } = useTranslation();
  const status = schema.lastPollResult.status;
  const completedActions = [{
    icon: <CloudDownloadOutlined />,
    label: t("actions:download.default"),
    onClick: () => download(schema)
  }, {
    disabled: schema.approvalRequested,
    icon: <AuditOutlined />,
    label: schema.approvalRequested ? "Approval Requested" : "Request Approval",
    key: "requestApproval",
    onClick: () => requestApproval(schema, onApprovalRequested, session?.user.email || "")
  }, {
    icon: <DeleteOutlined />,
    label: "Delete Job",
    key: "delete",
    onClick: () => props.onDeleteJob()
  }];
  if (status === "failed") {
    return <RemoteFailedJobActions onDeleteJob={props.onDeleteJob} renderFailModal={props => <BlazeJobFailModal modeId={failModeId} onCancel={props.onCancel} open={props.open} schema={schema} />} schema={schema} />;
  }
  return <RemoteJobActions {...props} schema={schema} status={status} completedActions={completedActions} data-sentry-element="RemoteJobActions" data-sentry-component="BlazeJobActions" data-sentry-source-file="BlazeJobActions.tsx" />;
};
const download = async (schema: Required<HacsJobSchema>) => {
  const link = await HacsJobMonitor.generateDownloadLink(schema);
  downloadPlainTextFile(link, {
    filename: schema.name,
    preventProcessing: true
  });
};
const requestApproval = async (schema: Required<HacsJobSchema>, onApprovalRequested: () => void, email = "") => {
  const [input, output] = await Promise.all([HacsJobMonitor.generateDownloadLink(schema, "input.csv"), HacsJobMonitor.generateDownloadLink(schema, "output.zip")]);
  await sendDataToHubspotForm({
    formId: REQUEST_APPROVAL_FORM,
    data: {
      email,
      description: `
        Deployment: ${window.location.origin}
        Run: ${schema.name}
        Folder ID: ${schema.folderId}
        Line Count: ${schema.lineCount}

        input.csv: ${input}

        output.zip: ${output}
      `
    }
  });
  onApprovalRequested();
};