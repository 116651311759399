import { HacsJobSchema } from "@/features/hacs";
import { RemoteJobInformation, RemoteJobTableBody } from "@/features/remote_jobs";
import { BlazeJobActions } from "@/tools/aggregate/blaze/components/BlazeJobActions";
import { BlazeJobFailModeId } from "@/tools/aggregate/blaze/components/BlazeJobFailModal";
import { BlazeJobStatus } from "@/tools/aggregate/blaze/components/BlazeJobStatus";
interface BlazeJobTableBodyProps {
  jobSchemas: Required<HacsJobSchema>[];
  onApprovalRequested: (schema: HacsJobSchema) => void;
  onDeleteJob: (schema: HacsJobSchema) => void;
  failModeId?: BlazeJobFailModeId;
}
const renderJobInformation = (record: Required<HacsJobSchema>) => {
  return <RemoteJobInformation schema={record} data-sentry-element="RemoteJobInformation" data-sentry-component="renderJobInformation" data-sentry-source-file="BlazeJobTableBody.tsx" />;
};
const renderStatus = (record: Required<HacsJobSchema>) => {
  return <BlazeJobStatus record={record} data-sentry-element="BlazeJobStatus" data-sentry-component="renderStatus" data-sentry-source-file="BlazeJobTableBody.tsx" />;
};

/**
 *
 * @param schema -
 * @param props -
 * @returns
 */
const renderActions = (schema: Required<HacsJobSchema>, props: BlazeJobTableBodyProps, failModeId?: BlazeJobFailModeId) => {
  return <BlazeJobActions onApprovalRequested={() => props.onApprovalRequested(schema)} onDeleteJob={() => props.onDeleteJob(schema)} schema={schema as any} failModeId={failModeId} data-sentry-element="BlazeJobActions" data-sentry-component="renderActions" data-sentry-source-file="BlazeJobTableBody.tsx" />;
};
export const BlazeJobTableBody = ({
  failModeId,
  ...props
}: BlazeJobTableBodyProps) => {
  return <RemoteJobTableBody<HacsJobSchema> {...props} renderers={{
    actions: (record, props) => renderActions(record, props, failModeId),
    jobInformation: renderJobInformation,
    status: renderStatus
  }} data-sentry-element="RemoteJobTableBody" data-sentry-component="BlazeJobTableBody" data-sentry-source-file="BlazeJobTableBody.tsx" />;
};