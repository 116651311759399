import UserPasswordInput from "./components/UserPasswordInput";
import {
  addKeyInSessionStorage,
  decryptData,
  encryptData,
  exportKey,
  generateNewUserKeyPairs,
  getKeyFromSessionStorage,
  importKey,
  unwrapEncryptedKeyPair,
  wrapKeyPair,
} from "./functions/users";
import { checkSupport as checkCryptographySupport } from "./utils/support";

export {
  generateNewUserKeyPairs,
  unwrapEncryptedKeyPair,
  addKeyInSessionStorage,
  getKeyFromSessionStorage,
  encryptData,
  decryptData,
  importKey,
  exportKey,
  wrapKeyPair,

  // utils/support.ts
  checkCryptographySupport,
  UserPasswordInput,
};
