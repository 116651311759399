import { BlazeJobTable } from "@/tools/aggregate/blaze/components/BlazeJobTable";
import { Tool } from "@/types/tools";
import { ProjectOutlined } from "@ant-design/icons";
const BlazeTool = () => {
  return <BlazeJobTable data-sentry-element="BlazeJobTable" data-sentry-component="BlazeTool" data-sentry-source-file="index.tsx" />;
};
export default {
  id: "blaze",
  category: "aggregate",
  keyPrefix: "aggregate.blaze",
  icon: <ProjectOutlined />,
  render: () => <BlazeTool></BlazeTool>
} as Tool;