import { unparse } from "@climaterisk/papaparse";
import type { Options } from "../types";
import { download } from "../utils";

// ensure we capture how the content is prepared for the parsing test
export const prepareCsvContent = unparse;

export const csv = (content: (string | number | undefined | null)[][], options: Options) => {
  download(
    prepareCsvContent(content),
    {
      includeBom: true,
      ...options,
    },
    "text/csv"
  );
};
