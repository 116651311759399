import { HomeDashboard } from "@/features/home_dashboard";
export const XdiHomeDashboard = () => {
  return <HomeDashboard banner={{
    hideOverlay: true,
    image: {
      alt: "XDI Branding Key Art",
      src: "/_/img/home-banner.png",
      style: {
        objectPosition: "right center"
      }
    }
  }} data-sentry-element="HomeDashboard" data-sentry-component="XdiHomeDashboard" data-sentry-source-file="XdiHomeDashboard.tsx" />;
};