import { HacsJobMonitor, HacsJobMonitorAggregator, HacsJobSchema } from "@/features/hacs";
import { RemoteJobTable } from "@/features/remote_jobs";
import { BlazeJobFailModeId } from "@/tools/aggregate/blaze/components/BlazeJobFailModal";
import { BlazeJobTableBody } from "@/tools/aggregate/blaze/components/BlazeJobTableBody";
import { BlazeJobTableHeader } from "@/tools/aggregate/blaze/components/BlazeJobTableHeader";
import { loadBlazeRuns, saveBlazeRuns } from "@/tools/aggregate/blaze/utils/local_storage";
import { FC, useEffect, useState } from "react";
interface BlazeJobTableProps {
  failModeId?: BlazeJobFailModeId;
  headerComponent?: FC;
  localStorageKey?: string;
}
export const BlazeJobTable = ({
  failModeId,
  headerComponent = BlazeJobTableHeader as FC,
  localStorageKey = "quickBlazeRuns"
}: BlazeJobTableProps) => {
  const [jobSchemas, setJobSchemas] = useState<Required<HacsJobSchema>[]>([]);
  const updateJobSchemas = (schemas: Required<HacsJobSchema>[]) => {
    setJobSchemas(schemas);
    saveBlazeRuns(schemas, localStorageKey);
  };
  const [aggregator] = useState(new HacsJobMonitorAggregator(updateJobSchemas));
  useEffect(() => {
    aggregator.setAll(loadBlazeRuns(localStorageKey));
    aggregator.startPolling();
    return () => {
      aggregator.stopPolling();
    };
  }, []);
  const onRunStart = (schema: HacsJobSchema) => {
    aggregator.addBySchema(schema);
  };
  const logApprovalRequestBySchema = (schema: HacsJobSchema) => {
    const newSchemas = jobSchemas.map(value => {
      if (schema.folderId === value.folderId) {
        return {
          ...(schema as Required<HacsJobSchema>),
          approvalRequested: true
        };
      }
      return value;
    });
    aggregator.stopPolling();
    aggregator.setAll(newSchemas);
    aggregator.startPolling();
  };
  const deleteBySchema = (schema: HacsJobSchema) => {
    const newSchemas = jobSchemas.reduce((list, value) => {
      return value.folderId === schema.folderId ? list : [...list, value];
    }, [] as Required<HacsJobSchema>[]);
    HacsJobMonitor.cancelJob(schema);
    aggregator.stopPolling();
    aggregator.setAll(newSchemas);
    aggregator.startPolling();
  };
  return <RemoteJobTable<HacsJobSchema> onRunStart={onRunStart} jobSchemas={jobSchemas} headerComponent={headerComponent} setJobSchemas={setJobSchemas} data-sentry-element="RemoteJobTable" data-sentry-component="BlazeJobTable" data-sentry-source-file="BlazeJobTable.tsx">
      <BlazeJobTableBody failModeId={failModeId} jobSchemas={jobSchemas} onApprovalRequested={logApprovalRequestBySchema} onDeleteJob={deleteBySchema} data-sentry-element="BlazeJobTableBody" data-sentry-source-file="BlazeJobTable.tsx" />
    </RemoteJobTable>;
};