import { PortfolioExportRawSchema } from "@/tools/aggregate/portfolio-export/schema";
import { parse } from "@climaterisk/papaparse";

/**
 *
 * @param line -
 * @param header -
 * @returns
 */
export const parsePortfolioExportUploadLine = (line: string, header: string): PortfolioExportRawSchema => {
  const content = [header, line].join("\n");
  const { data, errors } = parse<PortfolioExportRawSchema>(content, {
    header: true,
    dynamicTyping: true,
  });
  if (data[0] !== undefined) return data[0];
  throw new Error(errors.map((e) => `${e.code}: ${e.message} @ index: ${e.index}`).join("\n"));
};
