import { Settings } from "../types/settings";
import { Template } from "./templates";

/**
 *
 * @param param0 -
 * @returns
 */
export const buildSettings = ({ mode, hazards, steps, years }: Template): Settings => {
  const yearString = years.join(",");

  switch (mode) {
    case "severities":
      return {
        mode: "severities",
        steps,
        files: {
          "db/severities_settings.yaml": {
            hazard: hazards,
            flood_org: "jba",
            years: yearString,
            thresholds: {
              return_periods: [20, 100, 200, 1000],
            },
          },
        },
      };

    case "structural":
      return {
        mode: "structural",
        steps,
        files: {
          "db/Scenario.yaml": {
            hazards,
            organisation: "jba",
            years: yearString,
            waves: "off",
          },
          "db/structural_settings.yaml": {
            method: "default",
            sections: {
              inputs: "all",
              metadata: "all",
              results: {
                damage: ["total", "byHazard"],
                failure: ["total", "byHazard"],
                financial: ["totalRiskCost"],
                kpi: "none",
              },
            },
          },
        },
      };
  }
};
