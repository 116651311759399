import { HacsJobSchema } from "@/features/hacs";
import { RemoteJobTableHeader } from "@/features/remote_jobs";
import { BlazeNewRunModal } from "@/tools/aggregate/blaze/components/BlazeNewRunModal";
import { BlazeSettingsModal } from "@/tools/aggregate/blaze/components/BlazeSettingsModal";
import { SettingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { FC, useState } from "react";
import { v4 } from "uuid";
interface BlazeJobTableHeaderProps {
  onRunStart: (schema: HacsJobSchema) => void;
}
export const BlazeJobTableHeader = ({
  onRunStart
}: BlazeJobTableHeaderProps) => {
  const [settingsKey, setSettingsKey] = useState<string>();
  const openSettings = () => {
    setSettingsKey(v4());
  };
  return <RemoteJobTableHeader<HacsJobSchema> newRunModalComponent={BlazeNewRunModal as FC} onRunStart={onRunStart} data-sentry-element="RemoteJobTableHeader" data-sentry-component="BlazeJobTableHeader" data-sentry-source-file="BlazeJobTableHeader.tsx">
      <Button onClick={openSettings} data-sentry-element="Button" data-sentry-source-file="BlazeJobTableHeader.tsx">
        <SettingOutlined data-sentry-element="SettingOutlined" data-sentry-source-file="BlazeJobTableHeader.tsx" /> Settings
      </Button>
      <BlazeSettingsModal footer={<></>} key={settingsKey} open={!!settingsKey} onCancel={() => setSettingsKey("")} data-sentry-element="BlazeSettingsModal" data-sentry-source-file="BlazeJobTableHeader.tsx" />
    </RemoteJobTableHeader>;
};