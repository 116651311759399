import { WarningTwoTone } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./AcceptTerms.module.scss";
const LOCAL_STORAGE_KEY = "acceptedTerms";
export const AcceptTerms = () => {
  const [visible, setVisible] = useState(!!(localStorage && localStorage.getItem(LOCAL_STORAGE_KEY) !== "true"));
  const {
    t
  } = useTranslation("widgets", {
    keyPrefix: "firstTimeLogin"
  });
  const accept = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, "true");
    setVisible(false);
  };
  return <Modal closable={false} open={visible} title={<div className={styles.titleContainer}>
          <h2 className={styles.title}>{t("title")}</h2>
          <WarningTwoTone className={styles.titleIcon} twoToneColor={"#f59e0b"} />
        </div>} footer={<Button onClick={accept} type="primary" block size="large">
          {t("button")}
        </Button>} data-sentry-element="Modal" data-sentry-component="AcceptTerms" data-sentry-source-file="AcceptTerms.tsx">
      <div className={styles.blurbContainer}>
        <p className={styles.blurb}>
          <strong>{t("warning")}</strong>
        </p>
        {t("body").split("\n\n").map((text, index) => <p key={index} className={styles.blurb + " !mt-2"}>
              {text}
            </p>)}
      </div>
    </Modal>;
};