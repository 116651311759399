/**
 * Utils related to the versioning of the Hub over time.
 */

/**
 * When the climaterisk/hub repo was created (25/10/2022, 3:18 PM +11:00)
 * Rounded down to closest hour (3:00 PM +11:00), in milliseconds.
 */
export const HUB_CREATION_TIME = new Date("2022-10-25T04:00:00.000Z").getTime();
/** Number of milliseconds in any hour. */
const MS_IN_HOUR = 1000 * 60 * 60;

/**
 * Returns the number of hours between Hub creation time and given time,
 * rounded down, plus one, converted to six-digit hex with padding.
 */
export const getBuildCodeFromDate = (date: Date): string => {
  const buildTimeInMs = date.getTime();
  const diffInMs = buildTimeInMs - HUB_CREATION_TIME;
  const version = Math.floor(diffInMs / MS_IN_HOUR) + 1;
  return version > 0 ? version.toString(16).padStart(6, "0").toUpperCase() : "UNKNOWN";
};

/**
 * Returns the number of hours between Hub creation time and current build time,
 * rounded down, plus one, converted to six-digit hex with padding.
 */
export const getCurrentBuildCode = (): string => {
  return getBuildCodeFromDate(new Date(getCurrentBuildTimestamp()));
};

/**
 * Returns the latest commit timestamp, if available.
 * GitLab confirms it is ISO 8601:
 * https://docs.gitlab.com/ee/ci/variables/predefined_variables.html
 * If not available (in development environments), just return current time.
 * @returns ISO 8601 string.
 */
const getCurrentBuildTimestamp = (): string => {
  return process.env.NEXT_PUBLIC_CI_COMMIT_TIMESTAMP || new Date().toISOString();
};
