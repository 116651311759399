import { HacsJobSchema } from "@/features/hacs";
import { RemoteJobFailModalProps } from "@/features/remote_jobs";
import { Descriptions, Modal, Row, Typography } from "antd";
const {
  Text
} = Typography;
export type BlazeJobFailModeId = "logs" | "debugInfo";
interface FailMode {
  buttonTitle: string;
  modalTitle: string;
}
export const failModes: Record<BlazeJobFailModeId, FailMode> = {
  logs: {
    buttonTitle: "View Logs",
    modalTitle: "Last 20 Logs"
  },
  debugInfo: {
    buttonTitle: "View Error",
    modalTitle: "Error Information"
  }
};
interface BlazeJobFailModalProps extends RemoteJobFailModalProps<HacsJobSchema> {
  modeId: BlazeJobFailModeId;
}
export const BlazeJobFailModal = ({
  modeId,
  schema,
  ...props
}: BlazeJobFailModalProps) => {
  const failMode = failModes[modeId];
  const result = schema.lastPollResult;
  return <Modal footer={<></>} title={failMode.modalTitle} {...props} data-sentry-element="Modal" data-sentry-component="BlazeJobFailModal" data-sentry-source-file="BlazeJobFailModal.tsx">
      {modeId === "logs" && <>
          {result.status === "failed" && result.logs.map((log, index) => {
        return <Row key={index}>
                  <Text code>{log}</Text>
                </Row>;
      })}
        </>}
      {modeId === "debugInfo" && <>
          <Text>
            Something went wrong while processing your .csv file. Please contact the system administrator and provide
            the following information:
          </Text>
          <Descriptions title={schema.name} column={1} style={{
        marginTop: "1.5rem"
      }}>
            <Descriptions.Item label="Task">{schema.taskArn.split("hacs-main/")[1]}</Descriptions.Item>
            <Descriptions.Item label="Upload">{schema.folderId}</Descriptions.Item>
          </Descriptions>
        </>}
    </Modal>;
};