import { HacsJobSchema, HacsOmDragger, HacsOmUploadData, HacsSettings, HacsSettingsForm, uploadToHacs } from "@/features/hacs";
import { RemoteNewRunModal, RemoteNewRunModalProps } from "@/features/remote_jobs";
import { Button, Checkbox, Col, Progress, Typography } from "antd";
import { useState } from "react";
const {
  Title
} = Typography;
export type BlazeNewRunModalProps = RemoteNewRunModalProps<HacsJobSchema>;
export const BlazeNewRunModal = (props: BlazeNewRunModalProps) => {
  const [data, setData] = useState<HacsOmUploadData | undefined>();
  const [isBatch, setIsBatch] = useState(false);
  const [settings, setSettings] = useState<HacsSettings | undefined>();
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState([0, 0]);
  const onUpload = async () => {
    if (!data || !settings) {
      console.error("You tried to upload without data or settings being available. This should be impossible - contact an administrator.");
      return;
    }
    setUploading(true);
    const results = await uploadToHacs(data.file, setUploadProgress, settings, {
      coreCount: isBatch ? 2 : 1,
      lineCount: data.lineCount
    });
    props.onRunStart({
      name: data.file.name,
      lineCount: data.lineCount,
      folderId: results!.folderId,
      taskArn: results!.taskArn,
      startTime: new Date().getTime()
    });
  };
  const uploadPercent = uploadProgress[1] && uploadProgress[0] / uploadProgress[1] * 100;
  return <RemoteNewRunModal closable={!uploading} maskClosable={!uploading} {...props} data-sentry-element="RemoteNewRunModal" data-sentry-component="BlazeNewRunModal" data-sentry-source-file="BlazeNewRunModal.tsx">
      {!uploading && <>
          <Col span={24}>
            <HacsOmDragger onDataChange={setData} />
          </Col>
          {data && <>
              <Col span={24} style={{
          margin: ".5rem 0"
        }}>
                <Checkbox value={isBatch} onChange={e => setIsBatch(e.target.checked)}>
                  Batch Mode - Faster processing, but no RCP exports
                </Checkbox>
              </Col>
              <Col span={24}>
                <HacsSettingsForm setSettings={setSettings} />
              </Col>
              <Col span={24}>
                <Button block disabled={!settings} onClick={onUpload} type="primary">
                  Upload
                </Button>
              </Col>
            </>}
        </>}
      {uploading && <>
          <Title level={5}>Uploading... {uploadPercent.toFixed(2) + "%"}</Title>
          <Progress status="active" percent={uploadPercent} showInfo={false} />
        </>}
    </RemoteNewRunModal>;
};